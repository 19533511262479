import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import AudioModule from "../../../../components/shared/AudioModule";
import "../components.css";
import { AssetsType } from "../../../../enum/AssetsType.enum";
import AssetsDataService from "../../../../services/AssetsService";
import { useSelector, useDispatch } from "react-redux";
import { addProduct } from "../../../../redux/cartRedux";
import { useSnackbar } from "notistack";

import Sol from "../../../../images/SOL.png";
import Dollar from "../../../../images/dollar.png";
import CartService from "../../../../services/CartService";
import CongratsCard from "../../../Launchpad/components/CongratsCard";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { AiFillCloseCircle } from "react-icons/ai";

import { Keypair, PublicKey } from "@solana/web3.js";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import * as anchor from "@project-serum/anchor";
import { AccountLayout, TOKEN_PROGRAM_ID } from "@solana/spl-token";

let masterWallet: Keypair;
let MASTERWALLETPUBKEY: string | undefined = "";
// let allNFTsInWallet: any = [];
const GATEDTOKEN: any = process.env.REACT_APP_GATEDTOKEN_DEVNET;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

function HeighestRankingAlbum({
  asset_id,
  name,
  author,
  image,
  starting_price,
  valuation,
  quantity,
  type,
  cdn,
  revenue,
  asset,
  status,
  addedItem,
}: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [AssetQty, setAssetQty] = useState<any>("");
  const [cartAdded, setCartAdded] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [availableShares, setAvailableShares] = useState<number>(0);

  const currentUser = useSelector(
    (state: any) => state?.user?.currentUser?.data
  );
  const cartProducts = useSelector((state: any) => state.cart.products);
  const cartMarketplaceProducts = useSelector(
    (state: any) => state.cartMarketplace.products
  );

  const mintStatusValue = useSelector(
    (state: any) => state.adminSetting.mintStatus
  );

  var RemainingNFTs = asset?.cNFTs - AssetQty;

  const navigateTOTrack = () => {
    const assetName = name.replace(/\s+/g, "-").toLowerCase();
    const authorName = author.replace(/\s+/g, "-").toLowerCase();
    navigate(`/album/${authorName}-${assetName}`);
  };

  const AddToCart = () => {
    if (cartMarketplaceProducts.length > 0) {
      alert("Please remove marletplace cart to continue");
      return;
    }

    addedItem(asset);
    dispatch(addProduct({ ...asset, productQty: 1 }));
    // enqueueSnackbar("Added to cart", {
    //   variant: "success",
    //   autoHideDuration: 2000,
    //   anchorOrigin: {
    //     vertical: "bottom",
    //     horizontal: "left",
    //   },
    // });
  };

  function CloseCongrats() {
    navigate("/");
  }

  function collect() {
    navigate("/cart");
  }

  const retrieveTotalQtyInCart = (assetID: any) => {
    CartService.getTotalQtybyAssetID(assetID)
      .then((response: any) => {
        setAssetQty(response.data.data[0].TotalQty);
        console.log("retrieveTotalQtyInCart ", response.data.data);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const ClosePop = () => {
    setOpenModal(false);
  };

  const getAllNFTs = async (asset_id: any) => {
    if (asset_id) {
      var selectedAlbum;
      console.log(asset_id);

      if (asset_id === 39) {
        selectedAlbum = process.env.REACT_APP_MJ_MASTER;
        // console.log("Album =>> MJ");
      } else if (asset_id === 34) {
        selectedAlbum = process.env.REACT_APP_BB_MASTER;
        // console.log("Album =>> BB");
      } else if (asset_id === 401) {
        selectedAlbum = process.env.REACT_APP_NIR_MASTER;
        // console.log("Album =>> NIR");
      }

      // console.log("selectedAlbum =>> ", selectedAlbum);

      if (selectedAlbum) {
        masterWallet = Keypair.fromSecretKey(bs58.decode(selectedAlbum));
        MASTERWALLETPUBKEY = masterWallet.publicKey.toBase58();

        let allNFTsInWallet = [];
        const accessTk = GATEDTOKEN;
        const tokenAccounts = await connection.getTokenAccountsByOwner(
          new PublicKey(masterWallet.publicKey.toBase58()),
          {
            programId: TOKEN_PROGRAM_ID,
          }
        );

        // console.log("Loading the NFT mint addresses in the master wallet...");
        // console.log("Token                                        Balance");
        // console.log("------------------------------------------------------------");
        tokenAccounts.value.forEach((tokenAccount) => {
          const accountData = AccountLayout.decode(tokenAccount.account.data);
          var tempTokenAddress = new PublicKey(accountData.mint);

          //Record all NFTs in the master wallet into an array
          if (
            accountData.amount[0] === 1 &&
            tempTokenAddress.toBase58() !== accessTk
          ) {
            // console.log(
            //   `${new PublicKey(accountData.mint)}   ${accountData.amount[0]}`
            // );
            allNFTsInWallet.push(tempTokenAddress.toBase58());
          }
        });

        console.log(
          "Number of NFTs remaining HR ==>> ",
          allNFTsInWallet.length
        );
        return allNFTsInWallet.length;
      } else {
        console.log("Album not selected, selectAlbum = undefined HR");
      }
    }
  };

  useEffect(() => {
    const fetchAvailableNFTs = async () => {
      const availShares = await getAllNFTs(asset.asset_id);
      console.log("Fetched Availble NFTs HR ", availShares);
      if (availShares !== undefined) {
        setAvailableShares(availShares);
      }
      console.log("availableShares HR ", availableShares);
    };
    fetchAvailableNFTs();
  }, [asset?.asset_id, availableShares]);

  useEffect(() => {
    if (asset?.asset_id) {
      retrieveTotalQtyInCart(asset?.asset_id);
    }
  }, [asset?.asset_id]);

  useEffect(() => {
    const isProductAdded = cartProducts.find(
      (product: any) => product.asset_id === asset?.asset_id
    );
    isProductAdded ? setCartAdded(true) : setCartAdded(false);
  }, [asset?.asset_id, cartProducts]);

  return (
    <>
      {valuation > 0 ? (
        <div className="upcomingdrops-album-card" style={{ cursor: "pointer" }}>
          <div
            className="upcomingdrops-album-card-bg-img"
            onClick={navigateTOTrack}
          >
            <LazyLoadImage src={image} alt={name} className="card-image" />
            {AssetsType[type] === "Music" ||
            (AssetsType[type] === "Album" && cdn !== null) ? (
              <div className="audio-placement">
                <AudioModule data={cdn} />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div
            className="heighest-ranking-album-text"
            style={{ width: "90%", margin: "auto" }}
          >
            <div id="desktop-card-price">
              <p className="upcomingdrop-title">
                {name.length < 40 ? (
                  <>{`${name.substring(0, 40)}`}</>
                ) : (
                  <>{`${name.substring(0, 40)}..`}</>
                )}
              </p>
              {valuation === null || valuation === 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                <LazyLoadImage
                  src={Sol}
                  alt="sol"
                  className="upcomingdrop-price-img"
                />
                <p className="upcomingdrop-price">
                  {AssetsDataService.solToUSD(valuation) === 0
                    ? "TBD"
                    : AssetsDataService.solToUSD(valuation).toFixed(2)}
                </p>
              </div> */}

                  {mintStatusValue === "enable" && (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <LazyLoadImage
                          src={Dollar}
                          alt="dollar"
                          className="upcomingdrop-price-img"
                        />
                        {valuation > 0 && (
                          <p className="upcomingdrop-price mb-0 original-price">
                            {valuation === 0 ? "TBD" : "$" + starting_price}
                          </p>
                        )}
                        <p className="upcomingdrop-price mb-0">
                          {valuation === 0 ? "TBD" : "$" + valuation}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {status === 1 && (
              <div>
                {mintStatusValue === "enable" && (
                  <>
                    <div className="upcomingdrop-button">
                      {RemainingNFTs <= 0 ? (
                        <>
                          <button disabled>Sold out</button>
                        </>
                      ) : (
                        <>
                          {currentUser?.user_id ? (
                            <>
                              {cartAdded ? (
                                <>
                                  <button className="cart-added">
                                    Added to the cart
                                  </button>
                                </>
                              ) : (
                                <>
                                  {/* {currentUser?.verified_user === 0 ? ( */}
                                  {currentUser?.verified === 0 ? (
                                    <button disabled>Add to cart</button>
                                  ) : (
                                    <button onClick={AddToCart}>
                                      Add to cart
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Link to="/closed-alpha?modal=account&type=0">
                                <button>Add to cart</button>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {openModal && (
            <div>
              <div className="miniting-loading-screen">
                <div className="miniting-loading-screen-items">
                  {/* <p className="miniting-loaded-congratulation">Congratulations!</p> */}
                  <p className="miniting-loaded-screen-text">
                    You have added this item to cart
                  </p>

                  <CongratsCard nftImg={asset} user_id={currentUser?.user_id} />

                  <div className="close-btn">
                    <div onClick={ClosePop}>
                      <span>
                        <AiFillCloseCircle />
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                      className="explore-red-solid-button"
                      style={{ marginRight: "20px" }}
                      onClick={CloseCongrats}
                    >
                      <span>Keep Shopping</span>
                    </button>

                    <button
                      className="explore-red-outline-button"
                      style={{ marginLeft: "20px" }}
                      onClick={collect}
                    >
                      <span>Go to cart</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="upcomingdrops-album-card" style={{ cursor: "pointer" }}>
          <div className="upcomingdrops-album-card-bg-img">
            <LazyLoadImage src={image} alt={name} className="card-image" />
            {AssetsType[type] === "Music" ||
            (AssetsType[type] === "Album" && cdn !== null) ? (
              <div className="audio-placement">
                <AudioModule data={cdn} />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div
            className="heighest-ranking-album-text"
            style={{ width: "90%", margin: "auto" }}
          >
            <div id="desktop-card-price">
              <p className="upcomingdrop-title">
                {name.length < 40 ? (
                  <>{`${name.substring(0, 40)}`}</>
                ) : (
                  <>{`${name.substring(0, 40)}..`}</>
                )}
              </p>
              {valuation === null || valuation === 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                <LazyLoadImage
                  src={Sol}
                  alt="sol"
                  className="upcomingdrop-price-img"
                />
                <p className="upcomingdrop-price">
                  {AssetsDataService.solToUSD(valuation) === 0
                    ? "TBD"
                    : AssetsDataService.solToUSD(valuation).toFixed(2)}
                </p>
              </div> */}

                  {mintStatusValue === "enable" && (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <LazyLoadImage
                          src={Dollar}
                          alt="dollar"
                          className="upcomingdrop-price-img"
                        />
                        {valuation > 0 && (
                          <p className="upcomingdrop-price mb-0 original-price">
                            {valuation === 0 ? "TBD" : "$" + starting_price}
                          </p>
                        )}
                        <p className="upcomingdrop-price mb-0">
                          {valuation === 0 ? "TBD" : "$" + valuation}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {status === 1 && (
              <div>
                {mintStatusValue === "enable" && (
                  <>
                    <div className="upcomingdrop-button">
                      {RemainingNFTs <= 0 ? (
                        <>
                          <button disabled>Sold out</button>
                        </>
                      ) : (
                        <>
                          {currentUser?.user_id ? (
                            <>
                              {cartAdded ? (
                                <>
                                  <button className="cart-added">
                                    Added to the cart
                                  </button>
                                </>
                              ) : (
                                <>
                                  {/* {currentUser?.verified_user === 0 ? ( */}
                                  {currentUser?.verified === 0 ? (
                                    <button disabled>Add to cart</button>
                                  ) : (
                                    <button onClick={AddToCart}>
                                      Add to cart
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Link to="/closed-alpha?modal=account&type=0">
                                <button>Add to cart</button>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {openModal && (
            <div>
              <div className="miniting-loading-screen">
                <div className="miniting-loading-screen-items">
                  {/* <p className="miniting-loaded-congratulation">Congratulations!</p> */}
                  <p className="miniting-loaded-screen-text">
                    You have added this item to cart
                  </p>

                  <CongratsCard nftImg={asset} user_id={currentUser?.user_id} />

                  <div className="close-btn">
                    <div onClick={ClosePop}>
                      <span>
                        <AiFillCloseCircle />
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                      className="explore-red-solid-button"
                      style={{ marginRight: "20px" }}
                      onClick={CloseCongrats}
                    >
                      <span>Keep Shopping</span>
                    </button>

                    <button
                      className="explore-red-outline-button"
                      style={{ marginLeft: "20px" }}
                      onClick={collect}
                    >
                      <span>Go to cart</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HeighestRankingAlbum;
