import { createSlice } from "@reduxjs/toolkit";

const cartMarketplaceSlice = createSlice({
  name: "cartMarketplace",
  initialState: {
    products: [],
    quantity: 0,
    total: 0,
    totalQty: 0,
    time: 0,
  },
  reducers: {
    addProduct: (state: any, action: any) => {
      if (state.products.length === 0) {
        state.time = new Date().getTime();
      }
      state.quantity += 1;
      state.totalQty += 1;
      state.products.push(action.payload);
      state.total += action.payload.valuation * action.payload.productQty;

      //   state.quantity = 0;
      //   state.products = [];
      //   state.total = 0;
    },
    removeProduct: (state: any, action: any) => {
      state.quantity -= 1;
      state.products = action.payload.newCartProducts;
      state.total -= action.payload.removedProductTotal;
      state.totalQty -= action.payload.removedQty;
      if (state.quantity === 0) {
        state.time = 0;
      }
    },
    updateQty: (state: any, action: any) => {
      console.info("action payload ", action.payload);
      let totalAmount = 0;

      const index = state.products.findIndex((object: any) => {
        return object.asset_id === action.payload.asset_id;
      });
      if (index !== -1) {
        console.info("index ==> ", index);
        state.products[index].productQty = action.payload.updatedQty;
        state.total +=
          state.products[index].valuation * state.products[index].productQty;
      }

      state.products.forEach((product: any) => {
        totalAmount += product.valuation * product.productQty;
      });
      state.totalQty += action.payload.value;
      state.total = totalAmount;
    },
    deleteAllMarketplaceCarts: (state: any, action: any) => {
      state.quantity = 0;
      state.products = [];
      state.total = 0;
      state.totalQty = 0;
      return state;
    },
  },
});

export const {
  addProduct,
  removeProduct,
  updateQty,
  deleteAllMarketplaceCarts,
} = cartMarketplaceSlice.actions;
export default cartMarketplaceSlice.reducer;
